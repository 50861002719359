import React, { SyntheticEvent } from "react";
import {
  RadioButtonContainer,
  InputWrapper,
  RadioButtonLabel,
} from "./RadioButton.styles";

export type RadioButtonProps = {
  id: string;
  name: string;
  checked?: boolean;
  label?: string;
  onChange(options?: SyntheticEvent): void;
  labelTestId?: string;
  labelHidden?: boolean;
  value?: string;
  disabled?: boolean;
};

function RadioButton(props: RadioButtonProps) {
  const {
    id,
    name,
    checked = false,
    label,
    labelHidden = false,
    onChange,
    value,
    disabled = false,
  } = props;

  const onEnter = (e: { key: string }) => {
    if (e.key === "Enter") {
      onChange();
    }
  };

  return (
    <RadioButtonContainer>
      <InputWrapper>
        <input
          id={id}
          name={name}
          type="radio"
          checked={checked}
          onChange={onChange}
          onKeyPress={onEnter}
          value={value}
          disabled={disabled}
        />
        {/* eslint-disable jsx-a11y/label-has-associated-control */}
        <label htmlFor={id} />
        {/* eslint-enable jsx-a11y/label-has-associated-control */}
      </InputWrapper>

      {label && (
        <RadioButtonLabel
          htmlFor={id}
          aria-label={label}
          labelHidden={labelHidden}
          disabled={disabled}
        >
          {label}
        </RadioButtonLabel>
      )}
    </RadioButtonContainer>
  );
}

export default RadioButton;
