import React from "react";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import {
  RemoveCaregiversCancelButton,
  RemoveCaregiversModalContent,
  RemoveCaregiversModalHeader,
  RemoveCaregiversRevokeAccessButton,
  RemoveCaregiversRevokeAccessIcon,
  CloseModalIcon,
  CloseModalButton,
  RemoveCaregiversModalParagraph,
} from "./RemoveCaregiversModal.styles";
import { RemoveCaregiverModal } from "./Modal.styles";
import { TitleAndBody, Dismiss } from "./Modal.types";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import { User } from "common/models/user";
import { SiteSettings } from "common/utils/holvikaari";
import { Caregiver } from "common/models/caregiver";

export type RemoveCaregiversModalProps = TitleAndBody & {
  type: "remove_caregivers";
  className?: string;
  caregiver: Caregiver;
  intl: IntlShape;
  title: string;
  client: User;
  onClickAction: (id: string) => void;
};

const RemoveCaregiversModal = ({
  caregiver,
  dismiss,
  className,
  intl,
  onClickAction,
}: RemoveCaregiversModalProps & Dismiss) => {
  const caregiverActive = caregiver.active;

  return (
    <RemoveCaregiverModal
      className={className}
      onClick={(e: any) => e.stopPropagation()}
    >
      <RemoveCaregiversModalHeader>
        <h3>
          {intl.formatMessage({
            id: caregiverActive
              ? "modal.revoke_caregiver_access_title"
              : "modal.edit_caregiver_title",
          })}
        </h3>
        <CloseModalButton type="ghost" onClick={dismiss}>
          <CloseModalIcon
            name="remove"
            size="medium"
            alt={intl.formatMessage({ id: "modal.close" })}
            color={colors.gray900}
          />
        </CloseModalButton>
      </RemoveCaregiversModalHeader>
      <RemoveCaregiversModalContent>
        <RemoveCaregiversModalParagraph>
          {caregiverActive && (
            <b>
              {caregiver.full_name}
              <br />
            </b>
          )}
          {caregiver.email}
        </RemoveCaregiversModalParagraph>
        <br />
        <p>
          <FormattedMessage
            id={
              caregiverActive
                ? "modal.revoke_caregiver_access_info"
                : "modal.edit_caregiver_invitation_sent"
            }
            values={{ product_name: SiteSettings.product_name }}
          />
        </p>
        <RemoveCaregiversRevokeAccessButton
          onClick={() => onClickAction(caregiver.id)}
        >
          {caregiverActive ? (
            <RemoveCaregiversRevokeAccessIcon name="trashcan" size="24px" />
          ) : (
            <RemoveCaregiversRevokeAccessIcon name="close_circle" size="24px" />
          )}
          {intl.formatMessage({
            id: caregiverActive
              ? "modal.revoke_caregiver_access"
              : "modal.edit_caregiver_cancel_invitation",
          })}
        </RemoveCaregiversRevokeAccessButton>
        <RemoveCaregiversCancelButton onClick={() => dismiss()}>
          {intl.formatMessage({
            id: caregiverActive ? "modal.dismiss" : "modal.confirm_cap",
          })}
        </RemoveCaregiversCancelButton>
      </RemoveCaregiversModalContent>
    </RemoveCaregiverModal>
  );
};

export default injectIntl(RemoveCaregiversModal);
