import React, { useState, useEffect, useCallback, useRef } from "react";
import { tooltip } from "common/utils/tooltip";
import cn from "classnames";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { isEmpty, map } from "lodash";
import store from "store";
import { Spinner } from "@netmedi/frontend-design-system";
import { Source } from "common/models/symptoms";
import { Circle, EmptyCell, StyledSources } from "./SymptomGradeCircle.styles";

import {
  DataType,
  ParentSources,
} from "shared/components/SymptomTable/SymptomTable";
import { SymptomIcon } from "hcp/components/PopulationDashboard/PopulationDashboard.styles";
import { Symptom, TooltipSymptoms } from "../Tooltip/Tooltip.styles";
import {
  isSymptomOpened,
  NonRangeProps,
  renderedSources,
  SymptomGradeCircleProps,
  renderGrade,
  NonEmptyCommonProps,
  getGrade,
} from "./helpers";

// eslint-disable-next-line max-statements
function SymptomGradeCircle(
  props: SymptomGradeCircleProps & { intl: IntlShape },
) {
  const { intl } = props;
  const circleRef = useRef<any>();
  const onClose = useCallback(() => circleRef.current?.focus(), []);

  const onMount = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    if (props.isEmpty === true && props.tooltipText) {
      tooltip({
        title: props.tooltipText,
        target: e.target,
        position: "right",
        type: "auto",
        onClose,
      });
    }

    if (props.isEmpty !== true && props.getSources) {
      tooltip({
        title: <h4>{props.symptomName}</h4>,
        target: e.target,
        content: (
          <Sources
            grade={getGrade(props)}
            severityDescription={props.severityDescription}
            urgency={props.urgency}
            parentSources={props.parentSources}
            getSources={props.getSources}
          />
        ),
        className: "SymptomGradeCircle_tooltip",
        position: "left",
        onClose,
        focusOnContent: props.focusOnContent,
      });
    }
  };

  const onClickCb = (e: React.MouseEvent<HTMLElement>) => {
    props.onClick?.(e);
    onMount(e);
    if (store.getState().app.tooltip) return e.stopPropagation();
  };

  if (props.isEmpty === true) {
    return (
      <EmptyCell
        className={cn(props.className)}
        onClick={onClickCb}
        ref={circleRef}
        aria-label={intl.messages["empty"] as string}
      />
    );
  } else {
    const circleGrade = props.isRange ? Math.max(...props.grade) : props.grade;
    const circleUrgency = (props as NonRangeProps).urgency ?? 0;
    const circleSeverityGrade = circleUrgency ? circleUrgency : circleGrade;
    const label = `${intl.messages["attributes.reported_grade.grade"]} ${props.grade} - ${props.symptomName}`;
    return (
      <Circle
        ref={circleRef}
        aria-label={label}
        $sizeProportionalToGrade={props.sizeProportionalToGrade ?? false}
        $isSelected={props.selected}
        $isRange={props.isRange ?? false}
        $grade={circleGrade}
        $urgency={circleUrgency}
        className={cn(
          props.className,
          `SymptomGradeCircle_grade-${circleSeverityGrade}`,
        )}
        onClick={onClickCb}
        onKeyDown={e => e.key === "Enter" && onMount(e)}
      >
        {renderGrade(props)}
      </Circle>
    );
  }
}

type SourcesProps = React.HTMLAttributes<HTMLDivElement> &
  Pick<NonEmptyCommonProps, "getSources"> & {
    grade?: number;
    isChild?: boolean;
    dateOnly?: boolean;
    severityDescription: string;
    urgency?: number;
    parentSources?: ParentSources;
  };

export function Sources({
  grade,
  isChild,
  dateOnly,
  severityDescription,
  getSources,
  parentSources,
  ...rest
}: SourcesProps) {
  const [sources, setSources] = useState<Source[] | null>(null);
  const [openedSymptom, setOpenedSymptom] = useState<string | null>(null);
  useEffect(() => {
    let isSubscribed = true;
    getSources &&
      getSources().then(sources => {
        if (isSubscribed) setSources(sources);
      });
    return () => {
      isSubscribed = false;
    };
  }, [getSources]);

  const renderSeverity = () =>
    !isChild &&
    !dateOnly && (
      <>
        <h5>
          {grade} - {severityDescription}
        </h5>
        <hr />
      </>
    );

  return (
    <StyledSources {...rest} tabIndex={0}>
      {renderSeverity()}
      {renderSources()}
    </StyledSources>
  );

  function renderSources() {
    if (sources === null) return <Spinner noPadding color="white" />;

    if (sources.length === 0)
      return "Sorry, we could not get data points for this grade.";

    function renderparents(dps: DataType[], title: string) {
      function onSymptomNameClick(
        symptom: any,
        e: React.MouseEvent<HTMLElement>,
      ) {
        e.preventDefault();
        isEmpty(openedSymptom)
          ? setOpenedSymptom(symptom)
          : setOpenedSymptom(null);
      }

      return dps.map((data_point, idx) => (
        <React.Fragment key={idx}>
          <TooltipSymptoms
            $symptomWithDescription={isSymptomOpened(title, openedSymptom)}
            onClick={onSymptomNameClick.bind(null, title)}
            key={title + "div"}
          >
            <SymptomIcon
              name={
                isSymptomOpened(title, openedSymptom)
                  ? "direction_down"
                  : "direction_right"
              }
            />
            <p>{title}</p>
            <Symptom>
              <Circle
                $sizeProportionalToGrade={false}
                $isRange={false}
                $grade={data_point.grade}
                $urgency={data_point.urgency ?? 0}
                $frozen={true}
              >
                {" " + data_point.grade}
              </Circle>{" "}
            </Symptom>
          </TooltipSymptoms>
          {isSymptomOpened(title, openedSymptom) &&
            renderedSources(data_point.sources, isChild)}
        </React.Fragment>
      ));
    }

    const symptomBlock = (result: any) => (
      <>
        {!dateOnly && (
          <FormattedMessage id="symptom_table.sources" tagName="div" />
        )}
        <div>{result}</div>
      </>
    );

    if (parentSources) {
      return symptomBlock(map(parentSources, renderparents));
    }

    return isChild
      ? renderedSources(sources)
      : symptomBlock(renderedSources(sources, isChild));
  }
}

export default injectIntl(
  SymptomGradeCircle,
) as React.FC<SymptomGradeCircleProps>;
