import { combineReducers, Reducer } from "redux";
import intl from "./shared/reducers/intl";
import app from "./shared/reducers/app";
import user from "./common/reducers/user";
import client from "./client/reducers/client";
import tasks from "./client/reducers/tasks";
import staff from "./common/reducers/staff";
import calendar from "./client/reducers/calendar";
import inputForm from "./shared/reducers/inputForm";
import flash from "./common/reducers/flash";
import info_box from "./shared/reducers/infoBox";
import feed from "./hcp/reducers/feed";
import people from "./hcp/reducers/people";
import data_table from "./common/reducers/data_table";
import massNotifications from "./common/reducers/massNotifications";
import alert_conditions from "./shared/reducers/AlertCondition";
import consents from "./client/reducers/consents";
import populationDashboard from "./hcp/reducers/PopulationDashboard";
import pushDevices from "./client/reducers/pushDevices";
import treatmentPrograms from "./hcp/reducers/treatmentPrograms";
import loading from "common/reducers/loading";
import patientRecords from "client/reducers/patientRecords";
import sharedDocuments from "./hcp/reducers/SharedDocuments";
import teams from "hcp/reducers/teams";

export default (asyncReducers?: { [reducerName: string]: Reducer }) => {
  const appReducer = combineReducers({
    intl,
    app,
    user,
    flash,
    info_box,
    feed,
    client,
    tasks,
    staff,
    calendar,
    inputForm,
    alert_conditions,
    people,
    data_table,
    consents,
    populationDashboard,
    pushDevices,
    treatmentPrograms,
    loading,
    massNotifications,
    patientRecords,
    sharedDocuments,
    teams,
    ...asyncReducers,
  });

  const rootReducer = (state: any, action: any) => {
    if (action.type === "RESET_STATE") {
      return appReducer(undefined, action);
    } else {
      return appReducer(state, action);
    }
  };
  return rootReducer;
};
