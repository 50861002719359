import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { isNil } from "lodash";
import {
  typography,
  colors,
  scaffolding,
  symptomGrades,
} from "@netmedi/frontend-design-system/dist/styles/variables";

type CircleAttrs = {
  $isSelected?: boolean;
  $isRange: boolean;
};

type CircleProps = {
  $sizeProportionalToGrade: boolean;
  $isSelected?: boolean;
  $isRange: boolean;
  $urgency: number;
  $grade: number;
  $frozen?: boolean;
};

const isRangeStyles = css`
  white-space: nowrap;
`;

function getSeverityGrade(grade: number, urgency: number) {
  return urgency || grade;
}

const getColorByGrade = (grade: number, urgency: number) => {
  grade = getSeverityGrade(grade, urgency ?? 0);
  switch (grade) {
    case 0:
      return symptomGrades.severity0;
    case 1:
      return symptomGrades.severity1;
    case 2:
      return symptomGrades.severity2;
    case 3:
      return symptomGrades.severity3;
    case 4:
      return symptomGrades.severity4;
    default:
      break;
  }
};

const sizes = ["24px", "32px", "40px", "48px", "56px"];

const getsizeByGrade = (grade: number, urgency: number, enabled: boolean) => {
  grade = getSeverityGrade(grade, urgency ?? 0);
  return (
    enabled &&
    grade >= 0 &&
    css`
      width: ${sizes[grade]};
      height: ${sizes[grade]};
      line-height: ${sizes[grade]};
    `
  );
};

export const Circle = styled.div.attrs(
  ({ $isRange, $isSelected }: CircleAttrs) => ({
    tabIndex: isNil($isSelected) || $isSelected === true ? 0 : -1,
    role: "button",
    "data-testid": $isRange
      ? "symptom-grade-circle-is-range"
      : "symptom-grade-circle",
  }),
)<CircleProps>`
  width: ${sizes[0]};
  height: ${sizes[0]};
  line-height: ${sizes[0]};
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  font-size: ${typography.fontSizeMedium};
  font-weight: ${scaffolding.bold};
  color: ${colors.black};
  text-align: center;
  position: relative;

  &::before {
    will-change: opacity;
    transition:
      opacity 15ms linear,
      background-color 15ms linear;
    opacity: 0;
    z-index: 1;
    border-radius: 50%;
    position: absolute;
    top: 0;
    ${rtl`left: 0;`}
    width: 100%;
    height: 100%;
    background-color: white;
    content: "";
  }

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
  }

  ${({ $grade, $urgency }) =>
    $grade >= 0 &&
    css`
      background-color: ${getColorByGrade($grade, $urgency)};
    `}

  ${({ $grade, $frozen }) =>
    $grade >= 0 &&
    !$frozen &&
    css`
      &:hover {
        width: ${sizes[$grade + 1]};
        height: ${sizes[$grade + 1]};
        line-height: ${sizes[$grade + 1]};
      }
      &:active,
      &:focus {
        width: ${sizes[$grade + 1]};
        height: ${sizes[$grade + 1]};
        line-height: ${sizes[$grade + 1]};
      }
    `}
    
  ${({ $sizeProportionalToGrade, $grade, $urgency }) =>
    getsizeByGrade($grade, $urgency, $sizeProportionalToGrade)}
  ${({ $isRange }) => $isRange && isRangeStyles}
`;

export const UnderlinedSpan = styled.span`
  text-decoration: underline;
`;

export const SourceTitle = styled.div`
  font-weight: bold;
  padding-top: 12px;
`;

export const SourceField = styled.div.attrs(() => ({
  "data-testid": "symptom-grade-source-field",
}))`
  ${rtl`padding-left: 12px;`}
`;

export const DataPointCaption = styled.div`
  ${rtl`padding-left: 12px;`}
`;

export const DataPointTitle = styled.div`
  font-weight: 500;
  padding-top: 6px;
`;

export const EmptyCell = styled.div.attrs(() => ({
  "data-testid": "symptom-empty-cell",
  tabIndex: -1,
}))`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: ${colors.gray300};
  margin: 0 auto;
  cursor: pointer;
`;

export const StyledSources = styled.div.attrs(() => ({
  "data-testid": "symptom-grade-sources",
}))`
  max-height: 40vh;
  overflow: auto;

  hr {
    display: block;
  }

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
    outline-offset: 2px;
  }
`;

export const ImageThumbnail = styled.a`
  display: inline-block;
  margin: 6px;

  img {
    width: 120px;
    height: 120px;
    object-fit: contain;
  }
`;
